import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import ReactHTMLParser from 'react-html-parser';
import useOnScreen from '../../hooks/useOnScreen';

const parseContent = content => {
  if (React.isValidElement(content)) {
    return content;
  }
  if (typeof content === 'string') {
    return ReactHTMLParser(content);
  }
  return '';
};

const AccordionContent = props => {
  const {
    id,
    buttonId,
    isParent,
    isChild,
    contentBgColor,
    content,
    activeIndex,
    nestedAccordion
  } = props;

  const [checkOnScreenStatus, updateCheckOnScreenStatus] = useState(false);
  const contentDiv = useRef();
  const contentOnScreen = useOnScreen(contentDiv);

  // calculates the position of the accordion according to the current scroll and scrolls to the opening item
  useEffect(() => {
    if (
      checkOnScreenStatus &&
      !contentOnScreen &&
      contentDiv &&
      contentDiv.current &&
      typeof window !== 'undefined'
    ) {
      let offsetHeight = 100;

      // need to offset the sticky header if it exists
      const headerElement =
        typeof document !== 'undefined' &&
        document.getElementsByTagName('header');
      if (headerElement && headerElement[0]) {
        offsetHeight += headerElement[0].offsetHeight;
      }

      // if within a nested accordion, need to also offset the parent button height
      if (isChild) {
        offsetHeight += 100;
      }

      const scrollYPosition =
        window.pageYOffset +
        contentDiv.current.getBoundingClientRect().top -
        offsetHeight;
      updateCheckOnScreenStatus(false);
      window.scrollTo({ left: 0, top: scrollYPosition, behavior: 'smooth' });
    }
  }, [checkOnScreenStatus]);

  useEffect(() => {
    if (contentDiv && contentDiv.current && !contentOnScreen) {
      const timeout = setTimeout(() => {
        updateCheckOnScreenStatus(true);
      }, 100);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [activeIndex]);

  return (
    <div
      id={id}
      role="tabpanel"
      aria-labelledby={buttonId}
      className={classnames(
        'accordion__item-body',
        !isParent && contentBgColor
      )}
    >
      {/* Empty div in order to tell when the top of the content is not on screen */}
      <div ref={contentDiv} />
      {isParent ? nestedAccordion : parseContent(content)}
    </div>
  );
};

export default AccordionContent;
